<template>
  <div class="body">
    <div class="content">
      <div>
        <div class="content-video-top-text">正在直播</div>
        <div class="line"></div>
      </div>
      <div class="course-list" v-if="lives.length !== 0">
        <div class="course-item" v-for="(item, index) in lives" :key="index">
          <div class="course-content">
            <div class="course-img">
              <div class="live-label" >
                <img
                  v-if="item.cover"
                  :src="item.cover"
                  alt=""
                />
                <img
                  v-else
                  src="../../assets/empty.png"
                  alt=""
                  style="width: 280px; height: 145px"
                />
                <span v-if="item.start<item.newDate" style="background-color: #ff5e90;">直播中</span>
                <span v-else style="background-color: rgba(0,0,0,0.5)">未开播</span>
              </div>
            </div>
            <div class="course-content-item">
              <div class="course-name">
                {{ item.name }}
              </div>
              <div class="course-descr">
                {{ item.descr }}
              </div>
              <div class="time margin-top-10">
                直播日期：{{ item.startTime }}
              </div>
              <div class="time margin-top-10">直播时间：{{ item.endTime }}</div>
              <el-button 
                v-show="item.start<item.newDate"
                type="primary"
                round
                class="button"
                size="small"
                @click="viewLive(item)"
                >观看直播</el-button
              >
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="currentChange"
          :hide-on-single-page="true"
          class="pagination"
        >
        </el-pagination>
      </div>
      <div v-else>
        <el-empty description="暂无直播"></el-empty>
      </div>
      <footer class="footer">
        <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2021014802号</a>
      </footer>
    </div>
  </div>
</template>

<script>
import { getLiveList, getLiveInfo, getLivePlay } from "@/api/course.js";
export default {
  data() {
    return {
      lives: [],
      limt: 10,
      page: 1,
      total: 0,
      liveItem: {},
      timeBool:'',//直播开始时间。
    };
  },
  mounted() {
    this.getLiveList();
  },
  methods: {
    currentChange(page) {
      
    },
    viewLive(item) {
      getLiveInfo(item.id).then((res) => {
        if (res.code == 200) {
          this.liveItem = res.data;
          // used为0时才需要输入随机码
          if (this.liveItem.used === 0) {
            this.inputRandom();
          } else {
            this.openLive();
          }
        }
      });
    },
    openLive() {
      // 输入随机码先检测随机码的正确性
      getLivePlay({ random: this.random, id: this.liveItem.id }).then((res) => {
        if (res.code == 200) {
          if (localStorage.getItem("web-token")) {
            this.$router.push({
              path: "/liveBroadcastingRoom",
              query: { random: this.random, data: this.liveItem },
            });
          } else {
            this.$router.push({
              path: "/viewLive",
              query: { random: this.random, data: this.liveItem },
            });
          }
        }
      });
    },
    inputRandom() {
      this.$prompt("请输入随机码", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showClose: false,
      }).then(({ value }) => {
        if (value != null && value != "") {
          this.random = value;
          this.openLive();
        } else {
          this.$message.error("随机码不能为空");
        }
      });
    },
    getLiveList() {
      getLiveList().then((res) => {
        if (res != undefined) {
          this.lives = res.data.list;
          this.total = this.lives.length;
          // console.log(res.data.list[2].startTime);
          var time = "",
            date = "";
          this.lives.forEach((item, index) => {

            // 转换时间戳
            if(item.startTime){
            item.startTime =  item.startTime.replace(/-/g,'/')
            console.log(item.startTime);
            }
            //Number转换时间戳
            var startTime =Number(new Date(item.startTime))//直播开始时间
            var newDate = Number(new Date());     //当前时间

            //储存
            this.lives[index]['start']=startTime  
            this.lives[index]['newDate']=newDate  



            date = item.startTime.substring(0, 10);
            time = item.startTime.substring(11, 16);
            this.lives[index].endTime =
              time + " ~ " + item.endTime.substring(11, 16);
            this.lives[index].startTime = date;
            

          });
        }
      });
    },
    viewLiveBroadcast(item) {
      this.$router.push("/liveBroadcastingRoom");
    },
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 120) {
        return value.slice(0, 120) + "...";
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 1200px;
  height: 100vh;
  margin: 0 auto;
  .content {
    height: 279px;
    .content-video-top-text {
      width: 80px;
      font-weight: 400;
      font-size: 20px;
    }
    .line {
      height: 5px;
      width: 70px;
      margin-top: 4px;
      margin-bottom: 10px;
      background-color: #4394ff;
      border-radius: 10px;
    }
    .content-list {
      margin-top: 20px;
      height: 185px;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      padding: 15px 38px 20px 20px;
      .content-list-type {
        font-size: 18px;
        color: #000000;
        font-family: PingFang SC;
      }
      .content-list-all {
        margin-top: 15px;
        display: inline-flex;
        .content-list-image {
          width: 201px;
          height: 142px;
          border-radius: 5px;
          background-color: #4394ff;
        }
        .content-list-content {
          padding-left: 20px;
          .list-title {
            font-size: 18px;
            font-weight: 400;
            color: #000000;
          }
          .list-content {
            width: 940px;
            margin-top: 13px;
            line-height: 22px;
            height: 44px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .list-date {
            margin-top: 11px;
            height: 22px;
          }
          .list-time {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            height: 22px;
          }
          .button-blue {
            height: 31px;
            background: #4394ff;
            border-radius: 23px;
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            border: none;
            padding: 7px 18px;
          }
        }
      }
    }
  }
}
.margin-top-10 {
  margin-top: 10px;
}
.course-item {
  border: 1px solid #eeeeee;
  padding: 20px;
  margin-bottom: 20px;
  .course-type-name {
    margin-bottom: 15px;
  }
  .course-content {
    display: flex;
    .course-content-item {
      margin-left: 20px;
      position: relative;
      width: 100%;
      .course-name {
        margin-bottom: 13px;
        font-size: 18px;
      }
      .course-descr {
        color: #b1b1b1;
        line-height: 20px;
        height: 52px;
      }
      .time {
        color: #b1b1b1;
      }
      .button {
        position: absolute;
        right: 0;
        bottom: 0px;
      }
    }
  }
}
.list-content-text {
  font-size: 16px;
  font-weight: 400;
  color: #b1b1b1;
}
.pagination {
  text-align: center;
}
.live-label{
  position: relative;
  span{
    width: 51px;
    height: 18px;
    position: absolute;
    top: 4px;
    right: 4px;
    display: inline-block;
    
    font-size: 12px;
    color: white;
    border-radius: 2px;
    box-sizing: border-box;
    line-height: 18px;
    text-align: center;
    
  }
}
.course-img{ 
   width:280px; 
   height:145px;
} 
.course-img img{
  max-width:300px;
  height: 145px;
  width: 280px;
}
</style>